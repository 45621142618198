import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { getContentfulUrl } from '../shared/utilities'
import richTextOptions from '../shared/richTextOptions'
import embeddedRichTextOptions from '../shared/embeddedRichTextOptions'
import PreviousNext from '../components/PreviousNext'

const Blog = ({ pageContext, location }) => {
  const {
    title,
    publishDate,
    author,
    authorBio,
    authorProfileImage,
    body,
    previous,
    next
  } = pageContext
  const imgQuery = '?w=200&h=200'
  const urlImageProfile = getContentfulUrl(authorProfileImage) + imgQuery

  return (
    <Layout path={location.pathname}>
      <SEO title={title} />
      <div className="blog text-content">
        <Link to="/resources/blog" className="back-link">
          &larr; Blog
        </Link>
        <h1 className="mt-4">{title}</h1>
        {publishDate && <p className="post-date">{publishDate}</p>}
        {documentToReactComponents(body.json, richTextOptions)}
        <hr />
        <div className="row bio">
          <div className="col-md-4 my-auto">
            {urlImageProfile && (
              <img
                src={urlImageProfile}
                alt={author}
                className="mr-4"
                style={{ height: '200px' }}
              />
            )}
          </div>
          <div className="col-md-8">
            {author && <h4>{author}</h4>}
            {documentToReactComponents(authorBio.json, embeddedRichTextOptions)}
          </div>
        </div>
        <PreviousNext type="Blog" baseUrl="/resources/blog" previous={previous} next={next} />
      </div>
    </Layout>
  )
}

export default Blog

Blog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
